import React, { useState, useRef, useEffect } from "react";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import {
  TextField,
  Button,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputAdornment,
  IconButton,
  Switch,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import SaveIcon from "@mui/icons-material/Save";
import ReplayIcon from "@mui/icons-material/Replay";

function TaxCalculator() {
  const [basicSalary, setBasicSalary] = useState("");
  const [allowances, setAllowances] = useState("");
  const [taxAmount, setTaxAmount] = useState(0);
  const [taxDetails, setTaxDetails] = useState([]);
  const [isCalculated, setIsCalculated] = useState(false);
  const [showSalary, setShowSalary] = useState(false);
  const [showEPF, setShowEPF] = useState(false);
  const [showETF, setShowETF] = useState(false);
  const [epfEmployeeContribution, setEPFEmployeeContribution] = useState(0);
  const [epfEmployerContribution, setEPFEmployerContribution] = useState(0);
  const [etfEmployerContribution, setETFEmployerContribution] = useState(0);
  const tableRef = useRef(null);

  const handleClickShowSalary = () => setShowSalary((show) => !show);
  const handleMouseDownSalary = (event) => {
    event.preventDefault();
  };

  const calculateEPF = (baseSalary) => {
    const epfEmployeeRate = 0.08; // EPF employee contribution rate
    const epfEmployerRate = 0.12; // EPF employer contribution rate

    const epfEmployeeContribution = baseSalary * epfEmployeeRate;
    const epfEmployerContribution = baseSalary * epfEmployerRate;

    setEPFEmployeeContribution(epfEmployeeContribution);
    setEPFEmployerContribution(epfEmployerContribution);
  };

  const calculateETF = (baseSalary) => {
    const etfRate = 0.03; // ETF employer contribution rate

    const etfEmployerContribution = baseSalary * etfRate;

    setETFEmployerContribution(etfEmployerContribution);
  };

  const calculateTax = () => {
    const basic = Number(basicSalary.replace(/[^0-9.-]+/g, ""));
    const allow = Number(allowances.replace(/[^0-9.-]+/g, ""));
    const monthlySalary = basic + allow;
    const annualIncome = monthlySalary * 12;
    let taxAmount = 0;
    const taxRates = [
      { limit: 500000, rate: 6 },
      { limit: 500000, rate: 12 },
      { limit: 500000, rate: 18 },
      { limit: 500000, rate: 24 },
      { limit: 500000, rate: 30 },
      { limit: Infinity, rate: 36 },
    ];

    const reliefLimit = 1200000;
    let taxableIncome = annualIncome - reliefLimit;
    let taxDetailsList = [];

    if (taxableIncome <= 0) {
      setTaxAmount(0);
      setTaxDetails([]);
      setIsCalculated(true);
      return;
    }

    taxRates.forEach(({ limit, rate }) => {
      if (taxableIncome > 0) {
        let amount = Math.min(taxableIncome, limit);
        let tax = (amount / 12) * (rate / 100);
        taxAmount += tax;
        taxDetailsList.push({ rate, amount: tax });
        taxableIncome -= amount;
      }
    });

    setTaxAmount(taxAmount);
    setTaxDetails(taxDetailsList);

    if (showEPF) {
      calculateEPF(basic);
    }
    if (showETF) {
      calculateETF(basic);
    }

    setIsCalculated(true);
  };

  const resetCalculator = () => {
    setBasicSalary("");
    setAllowances("");
    setTaxAmount(0);
    setTaxDetails([]);
    setIsCalculated(false);
    setEPFEmployeeContribution(0);
    setEPFEmployerContribution(0);
    setETFEmployerContribution(0);
    setShowEPF(false);
    setShowETF(false);
  };

  const downloadImage = () => {
    const tables = document.querySelectorAll(".calcTables");

    Promise.all(Array.from(tables).map((table) => html2canvas(table))).then(
      (canvases) => {
        const combinedCanvas = document.createElement("canvas");
        const context = combinedCanvas.getContext("2d");

        const width = Math.max(...canvases.map((canvas) => canvas.width));
        const height = canvases.reduce((acc, canvas) => acc + canvas.height, 0);

        combinedCanvas.width = width;
        combinedCanvas.height = height;

        let offsetY = 0;
        canvases.forEach((canvas) => {
          context.drawImage(canvas, 0, offsetY);
          offsetY += canvas.height;
        });

        combinedCanvas.toBlob(function (blob) {
          saveAs(blob, "tax-calculations.png");
        });
      }
    );
  };

  useEffect(() => {
    if (isCalculated) {
      const basic = Number(basicSalary.replace(/[^0-9.-]+/g, ""));
      const allow = Number(allowances.replace(/[^0-9.-]+/g, ""));

      if (showEPF) {
        calculateEPF(basic);
      } else {
        setEPFEmployeeContribution(0);
        setEPFEmployerContribution(0);
      }

      if (showETF) {
        calculateETF(basic);
      } else {
        setETFEmployerContribution(0);
      }

      if (basic > 0 || allow > 0) {
        calculateTax();
      }
    }
  }, [showEPF, showETF, basicSalary, allowances, isCalculated]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Typography variant="h4" gutterBottom align="center">
        Calculate Personal Income Tax - Sri Lanka
      </Typography>
      <Box
        component="form"
        display="flex"
        sx={{
          "& > :not(style)": { m: 1, width: "25ch" },
        }}
      >
        <TextField
          id={`BasicSalary${showSalary ? "" : "HideInputText"}`}
          label={`Basic Salary`}
          variant="outlined"
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
          margin="normal"
          type="text"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle salary visibility"
                  onClick={handleClickShowSalary}
                  onMouseDown={handleMouseDownSalary}
                  edge="end"
                  color={showSalary ? "warning" : "success"}
                >
                  {showSalary ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={basicSalary}
          onChange={(e) => setBasicSalary(e.target.value)}
          placeholder="Amount (LKR)"
        />

        <TextField
          id={`Allowances${showSalary ? "" : "HideInputText"}`}
          label={`Allowances`}
          variant="outlined"
          style={{
            width: "100%",
            maxWidth: "500px",
          }}
          margin="normal"
          type="text"
          autoComplete="off"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle salary visibility"
                  onClick={handleClickShowSalary}
                  onMouseDown={handleMouseDownSalary}
                  edge="end"
                  color={showSalary ? "warning" : "success"}
                >
                  {showSalary ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={allowances}
          onChange={(e) => setAllowances(e.target.value)}
          placeholder="Amount (LKR)"
        />
      </Box>

      <Box display="flex" gap={2} alignItems="center">
        <Switch
          checked={showEPF}
          onChange={() => setShowEPF((prev) => !prev)}
          inputProps={{ "aria-label": "Toggle EPF" }}
        />
        <Typography>EPF</Typography>
        <Switch
          checked={showETF}
          onChange={() => setShowETF((prev) => !prev)}
          inputProps={{ "aria-label": "Toggle ETF" }}
        />
        <Typography>ETF</Typography>
      </Box>

      <Box display="flex" gap={2}>
        <Button
          variant="contained"
          color="primary"
          onClick={calculateTax}
          disabled={
            (!basicSalary && !allowances) ||
            (Number(basicSalary.replace(/[^0-9.-]+/g, "")) === 0 &&
              Number(allowances.replace(/[^0-9.-]+/g, "")) === 0)
          }
        >
          Calculate
        </Button>
        {isCalculated && (
          <Button
            variant="contained"
            color="secondary"
            onClick={resetCalculator}
          >
            <ReplayIcon />
          </Button>
        )}
        {isCalculated && (
          <Button variant="contained" color="success" onClick={downloadImage}>
            <SaveIcon />
          </Button>
        )}
      </Box>
      <Box minWidth="100%" className="calcTables">
        {isCalculated && (
          <>
            {showEPF && (
              <>
                <Typography variant="h6" gutterBottom align="center">
                  EPF Calculation
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 5 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Description
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Contribution
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Employee Contribution (8%)</TableCell>
                        <TableCell>
                          {epfEmployeeContribution.toLocaleString("en-US", {
                            style: "currency",
                            currency: "LKR",
                          })}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Employer Contribution (12%)</TableCell>
                        <TableCell>
                          {epfEmployerContribution.toLocaleString("en-US", {
                            style: "currency",
                            currency: "LKR",
                          })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            {showETF && (
              <>
                <Typography variant="h6" gutterBottom align="center">
                  ETF Calculation
                </Typography>
                <TableContainer component={Paper} sx={{ mb: 5 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Description
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Contribution
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>Employer Contribution (3%)</TableCell>
                        <TableCell>
                          {etfEmployerContribution.toLocaleString("en-US", {
                            style: "currency",
                            currency: "LKR",
                          })}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            )}
            <Typography variant="h6" gutterBottom align="center">
              Tax Calculation
            </Typography>
            <TableContainer component={Paper} sx={{ mb: 5 }}>
              <Table ref={tableRef}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Monthly Salary (Annual Salary/12)
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Rate (%)
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>Tax</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>Up to 1,200,000/12</TableCell>
                    <TableCell>Relief</TableCell>
                    <TableCell>-</TableCell>
                  </TableRow>
                  {taxDetails.map((detail, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        {index === 0
                          ? "First 500,000/12"
                          : index === taxDetails.length - 1
                          ? "Balance 4,200,000/12"
                          : "Next 500,000/12"}
                      </TableCell>
                      <TableCell>{detail.rate}</TableCell>
                      <TableCell>
                        {detail.amount.toLocaleString("en-US", {
                          style: "currency",
                          currency: "LKR",
                        })}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={2}>Total</TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      {taxAmount.toLocaleString("en-US", {
                        style: "currency",
                        currency: "LKR",
                      })}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
      </Box>
    </Box>
  );
}

export default TaxCalculator;
