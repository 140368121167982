import React from "react";
import { AppBar, Toolbar, Typography, Container, Button, Link } from "@mui/material";
import TaxCalculator from "../components/TaxCalculator";
import VolunteerActivismIcon from '@mui/icons-material/VolunteerActivism';
import '../App.css';

function App() {
  return (
    <div>
      <AppBar position="sticky">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }} >
            
            <Link href="https://taxcalculator.site" underline="none" color="white">
            Tax Calculator Site
  </Link>
          </Typography>
          <Button color="inherit" endIcon={<VolunteerActivismIcon />} variant="outlined">50 LKR</Button>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 5 }}>
        <TaxCalculator />
      </Container>
    </div>
  );
}

export default App;
